.authcontent {
	/* width: 50%; */
	width: 30rem;
	max-width: 80%;
	margin: 0.5rem auto;
}

.form {
	padding: 0.5rem;
	text-align: left;
}

.control {
	margin-bottom: 0.5rem;
}

.control label {
	display: block;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.control input,
.control textarea {
	display: block;
	font: inherit;
	border-radius: 4px;
	border: 1px solid #ccc;
	padding: 0.25rem;
	width: 100%;
}

.actions {
	margin-top: 2rem auto;
	display: flex;
	justify-content: center; /* Center items horizontally */
	align-items: center;
}

.actions button {
	margin: 2rem;
	font: inherit;
	cursor: pointer;
	background-color: #5101d1;
	color: white;
	padding: 0.5rem 1.5rem;
	border: 1px solid #5101d1;
	border-radius: 25px;
	font-weight: bold;
	color: white;
  width: 80%;
}

.actions button:hover,
.actions button:active {
	background-color: #2a0567;
	border-color: #2a0567;
	color: white;
}
